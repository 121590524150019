import React, { useEffect } from "react";
import Close from "../Assets/close_1828665 1.svg";
import axios from "axios";
import { message } from "antd";

function SuccessPayment() {
  const paymentStatus = async () => {
    try {
      const response = await axios.post(
        `${process.env.REACT_APP_BASE_URL}/get-status-by-payment-id`,
        {
          payment_id: localStorage.getItem("payment_id"),
          // user_id:
        },
        {
          headers: {
            content: "application/json",
            Authorization: `Bearer ${localStorage.getItem("token")}`,
          },
        }
      );

      console.log(response.data);
      localStorage.removeItem("payment_id");
      window.location.reload();
    } catch (err) {
      console.log(err);
      message.error("Something went wrong");
    }
  };

  useEffect(() => {
    if(localStorage.getItem("payment_id")) {
      paymentStatus();
    }
  }, []);
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: "110px",
      }}
    >
      <div
        style={{
          boxShadow: "0px 8px 16px 0px rgba(0, 0, 0, 0.15)",
          padding: "71px 31px 134px 38px",
          width: "80%",
        }}
      >
        <div
          style={{
            borderRadius: "133px",
            background: "#D1FADF",
            width: "200px",
            height: "200px",
            margin: "auto",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <img src="/images/Icon.png" />
        </div>
        {/* <div style={styles.text}>Payment Successfull.</div> */}
        <div style={styles.text}>Your payment done successfully!</div>
      </div>
    </div>
  );
}

export default SuccessPayment;

const styles = {
  text: {
    fontSize: "32px",
    fontWeight: "bold",
    textAlign: "center",
  },
};
